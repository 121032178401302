import { toast } from "react-toastify";
import API from "../../API";
import { GET_ALL_EXTENSIONS, GET_PARTICULAR_PLAN, GET_PLAN_DETAIL, GET_PLAN_LIST, GET_PURCHASE_PLAN } from "../action.type";

export const getPlanList = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/plan/getPlanList`, method: 'get', params: params
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_PLAN_LIST,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updatePlan = (id, data, params, fromPlan) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/plan/updatePlan/${id}`, method: 'put', data: data
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_PLAN_LIST,
                    payload: ApiCall?.data
                });
                if(params) {
                    dispatch(getPlanList(params));
                }
                if(!fromPlan) {
                    window.location.href = '/subscription-plans'
                }
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addPlan = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/plan/AddPlan`, method: 'post', data: data
            });
            if (ApiCall?.status === 200) {
                window.location.href = "/subscription-plans";    
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getPruchasePlan = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/plan/GetPruchasePlan`, method: 'get', params: params
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_PURCHASE_PLAN,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getPlanById = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/plan/getPlanById/${id}`, method: 'get'
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_PLAN,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getPruchasePlanDetail = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/plan/GetPruchasePlanDetail/${id}`, method: 'get'
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_PLAN_DETAIL,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};
export const getAllExtensionsName = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `super/admin/extension/GetAllExtensionsName`, method: 'get'
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_ALL_EXTENSIONS,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};