import { GET_ALL_EXTENSIONS, GET_PARTICULAR_PLAN, GET_PLAN_DETAIL, GET_PLAN_LIST, GET_PURCHASE_PLAN } from "../action.type";

const initialState={
    planList: [],
    purchasePlan: [],
    particularPlan: {},
    planDetail: {},
    extensions: []
}                                 

export const subscriptionReducer=(state=initialState, action)=> {
    switch (action.type) {
      case GET_PLAN_LIST:
        return {
            ...state,
            planList: action.payload,
        };
      case GET_PURCHASE_PLAN:
        return {
            ...state,
            purchasePlan: action.payload,
        };
      case GET_PARTICULAR_PLAN:
        return {
            ...state,
            particularPlan: action.payload,
        };
      case GET_PLAN_DETAIL:
        return {
            ...state,
            planDetail: action.payload,
        };
      case GET_ALL_EXTENSIONS:
        return {
            ...state,
            extensions: action.payload,
        };
      
      default:
        return state;
    }
  }
